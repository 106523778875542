import React from 'react';
import { Link } from 'react-router-dom';

class Footer extends React.Component {
    render() {
        return (
            <footer>
                <div className="ui vertical footer segment" style={{backgroundColor: "#fff", marginTop: "50px", paddingTop:"50px", paddingBottom:"50px"}}>
                <div className="ui container">

                    <div className="ui stackable divided equal height grid">
                        <div className='four wide column'>
                            <img src="/assets/img/footer_logo.png" className="logo-footer" />
                            <p>Kami menyediakan kebutuhan harian secara online, dan mengantar pesanan sampai ke tujuan anda.</p>
                        </div>
                        <div className="three wide column">
                            <h4 className="ui header footer-heading">Download</h4>
                            <div className="ui link list">
                            <a href="https://play.google.com/store/apps/details?id=com.henata.app" target="_blank" rel="noopener noreferrer">
                                <img src="/assets/img/google_play.png" className="playstore-img-footer" /></a>
                            </div>
                        </div>
                        <div className="three wide column">
                            <h4 className="ui header footer-heading"></h4>
                            <div className="ui link list">
                                <Link to="/about" class="item">Tentang kami</Link>
                                {/* <Link to="/manual" class="item">Panduan penggunaan</Link> */}
                                <Link to="/privacy" class="item">Kebijakan privasi</Link>
                                <Link to="/terms" class="item">Syarat dan ketentuan</Link>
                                <a href="https://www.henata.com/blog" class="item">Blog</a>
                                {/* <Link to="/reach" class="item">Kontak</Link> */}
                            </div>
                        </div>
                        {/* <div className="three wide column">
                            <h4 className="ui header footer-heading">Kategori</h4>
                            <div className="ui link list">
                                <Link to="/kategori/food" class="item">Food</Link>
                                <Link to="/kategori/non-food" class="item">Non Food</Link>
                                <Link to="/kategori/general-merchandise" class="item">General Merchandise</Link>
                            </div>
                        </div> */}
                        <div className="three wide column">
                            <h4 className="ui header footer-heading">Customer Support</h4>
                            <div className="ui link list">
                            <a href="https://wa.me/6282142036430" className="item"><div className="footer-text-link"><i className="circular whatsapp icon"></i> +6281-1332-6282</div></a>
                            </div>
                        </div>
                        <div className="three wide column">
                            <h4 className="ui header footer-heading">Media Sosial</h4>
                            <div className="ui link list">
                            <a href="https://instagram.com/henatastore" className="flexing item" target="_blank" rel="noopener noreferrer"><div className="footer-text-link"><i className="circular instagram icon"></i>henatastore</div></a>
                            <a href="https://tiktok.com/henatastore" className="flexing item" target="_blank" rel="noopener noreferrer"><div className="footer-text-link"><i className="circular tiktok icon"></i>henatastore</div></a>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            </footer>
            
        );
    }
}

export default Footer;