import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchSearchProductByKeywordWithDiskon } from '../actions/search';
import { Helmet } from 'react-helmet';
import queryString from 'query-string';
import { MOBILE_SITE } from '../actions/types'
import { connectSearchPanel as SearchBar } from '../universal/SearchBar';

import history from '../../history'

import SearchList from '../search/SearchList';
import Pagination from '../universal/Pagination';
import "../css/search.css"

class Search extends React.Component {
    constructor(props) {
        super(props);
        var qs = queryString.parse(this.props.location.search, {ignoreQueryPrefix: true});
        this.state = {
            query: qs.q,
            activePage : 1
        }
    }
    async loadProductFromPage(keyword, page) {
        await this.props.fetchSearchProductByKeywordWithDiskon(keyword, page-1);
        
    }
    onPaginationChange = async (page) => {
        this.setState({activePage: page});

        this.loadProductFromPage(this.state.query, page);
    }
    onFormSubmit = async event => {
        event.preventDefault();

        //akses api untuk load
        //pakai state yang sudah diubah dari onChange di input
        
        //ubah url
        //let path = "/search?q=" + this.state.query;
        history.push({
            pathname: "/search",
            search: "?q="+this.state.query+"&src=bar&by=products"
        });
    }
    doRenderHeader(keyword,total_result) {
        //console.log(this.props.location)
        var titleStr = "Cari '" + keyword + "' (" + total_result + " produk) - Henata"
        return (
            <Helmet>
                <title>{titleStr}</title>
                <meta name="robots" content="nofollow" />
                <link rel="alternate" media="only screen and (max-width: 640px)" href={MOBILE_SITE + this.props.location.pathname} />
            </Helmet>
        );
    }
    doRenderSearchBar() {
        return (
            <div className="ui fixed menu">
                <div className="ui container" >
                    <Link to="/" className="header borderless item" style={{border: "none", borderRight: "none"}}>
                        <img alt="henata" style={{width: '100px'}} className="logo" src="/assets/img/logo.png">
                        </img>
                    </Link>
                    
                    <div style={{width: '100%', margin: '8px'}}>
                        <form className="ui form" onSubmit={this.onFormSubmit}>
                            <div className="ui fluid action input">
                            <input className="search-input" type="search" placeholder="Cari produk..." aria-label="Search" value={this.state.query} onChange={(e) => {this.setState({query: e.target.value})}}/>
                            <button className="ui button" type="submit"><i className="search icon"></i></button>
                            </div>
                        </form>
                    </div>
                    
                </div>
                
            </div>
        );
    }
    doRenderPage() {
        var rendered = <div></div>;
        if (this.props.resultKeyword.isLoaded === true) {
            rendered = (
                <div>
                    {this.doRenderHeader(this.props.resultKeyword.keyword, this.props.resultKeyword.total)}
                    <div className="result_keterangan"> Menampilkan hasil {this.props.resultKeyword.from}-{this.props.resultKeyword.to} dari {this.props.resultKeyword.total} </div>
                    <div className="ui grid">
                        {/* <div className="three wide column">
                            
                        </div> */}
                        <div className="sixteen wide column">
                            <SearchList products={this.props.resultKeyword.products} />
                            <div className="search_pagination">
                            <Pagination  total={this.props.resultKeyword.total} activeNow={this.state.activePage} onPaginationChange={this.onPaginationChange} />
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            rendered = (
                <div>
                    Loading...
                </div>
            )
        }
        return rendered;
    }
    async componentDidMount() {
        //console.log("Panggil dari mount")
        var qs = queryString.parse(this.props.location.search, {ignoreQueryPrefix: true});
        if (this.props.resultKeyword.isLoaded === true) {
            if (this.props.resultKeyword.keyword !== qs.q) {
                //reload api
                //console.log(qs.q);
                await this.loadProductFromPage(qs.q, 1);
            }
        }
        else {
            await this.loadProductFromPage(qs.q, 1);
        }
    }
    async componentDidUpdate() {
        //console.log("Panggil render page");
        var qs = queryString.parse(this.props.location.search, {ignoreQueryPrefix: true});
        if (this.props.resultKeyword.isLoaded === true) {
            if (this.props.resultKeyword.keyword !== qs.q) {
                //reload api
                //console.log(qs.q);
                await this.loadProductFromPage(qs.q, 1);
                //console.log("Dari keyword beda", this.props.resultKeyword)
                this.setState({query: qs.q})
            }
        }
        else {
            //console.log("belum loaded")
            await this.loadProductFromPage(qs.q, 1);
            //console.log("dari belum loaded", this.props.resultKeyword)
        }
    }

    render() {
        
        return (
            <div>
                {/* {this.doRenderSearchBar()} */}
                <SearchBar fromSearch="true" defaultQuery={this.state.query}/>
                <div class="ui main container">
                    <div>
                        {this.doRenderPage()}
                    </div>
                    
                </div>
            </div>
            
        );
    }
}

const mapStateToProps = (state) => {
    return {
        resultKeyword: state.searchProductByKeyword
    };
}
const connectSearch = connect(mapStateToProps, {
    fetchSearchProductByKeywordWithDiskon
})(Search)

export  {connectSearch};