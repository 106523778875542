import React from 'react';
import { Link } from 'react-router-dom';

import history from '../../history';
import { fetchSearchKeywordSuggestion, resetSearchKeywordSuggestion } from '../actions/search';
import { connect } from 'react-redux';

import AccountMenu from '../page_components/SearchBar/AccountMenu';
import UtilitiesMenu from '../page_components/SearchBar/UtilitiesMenu';
import "../css/search.css"
import Cookies from 'universal-cookie';

class SearchBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = { query: '', isSearchInputFocus : false, isMouseInsidePanel: false, fromSearchNewKeywordSubmitted: false };
        this.stickyRef = React.createRef();

        this.cookies = new Cookies();
    }
    onFormSubmit = event => {
        event.preventDefault();

        if (this.props.fromSearch ==="true") {
            //this.setState({ isSearchInputFocus: false});
            this.setState({ fromSearchNewKeywordSubmitted : true});
            //console.log("Setelah pergantian state", this.state.query);
            //this.searchInput.blur();
            history.push({
                pathname: "/search",
                search: "?q="+this.state.query+"&src=bar&by=products"
            });
        }
        else {
            history.push({
                pathname: "/search",
                search: "?q="+this.state.query+"&src=bar&by=products"
            });
        }
        //this.props.onSubmit(this.state.query);
    }
    onMouseEnterPanel = event => {
        //console.log("Mouse is entering")
        this.setState({isMouseInsidePanel: true})
    }
    onMouseLeavePanel = event => {
        //console.log("Mouse is leaving");
        this.setState({isMouseInsidePanel: false})
    }
    onSearchInputChange = event => {
        //console.log("Actual value", event.target.value)
        if (event.target.value !== "") {
            this.loadKeywordSuggestion(event.target.value);
        }
        else {
            this.props.resetSearchKeywordSuggestion();
        }
        this.setState({query: event.target.value});
    }
    onSearchInputFocus = event => {
        //console.log("Sekarang focus", this.state.isMouseInsidePanel)
        //console.log("First focus actual value", event.target.value)
        if (event.target.value !== "") {
            this.loadKeywordSuggestion(event.target.value);
        }
        else {
            this.props.resetSearchKeywordSuggestion();
        }
        this.setState({ isSearchInputFocus: true})
    }
    onSearchInputBlur = event => {
        //console.log("executing blurr...")
        //console.log("Sekarang:", this.state.isMouseInsidePanel)
        if (this.props.fromSearch === "true") {
            if (this.state.isMouseInsidePanel === false) {
                //console.log("The mouse is not inside!!")
                this.setState({ isSearchInputFocus: false, fromSearchNewKeywordSubmitted: false})
            }
            else {
                //console.log("fromSearchKeyword", this.state.fromSearchNewKeywordSubmitted);
                if (this.state.fromSearchNewKeywordSubmitted === true) {
                    //console.log("Blurring THINGS...")
                    //disable searchinputfocus dan juga kembalikan value dari fromSearchNewKeywordSubmitted ke false
                    this.setState({ isSearchInputFocus: false, fromSearchNewKeywordSubmitted: false })
                }
                else {
                    //somehow fixing the bug in firefox
                    setTimeout(() => {this.searchInput.focus();}, 10)
                }
            }
        }
        else {
            if (this.state.isMouseInsidePanel === false) {
                this.setState({ isSearchInputFocus: false})
            }
            else {
                //somehow fixing the bug in firefox
                setTimeout(() => {this.searchInput.focus();}, 10)
            }
        }
    }
    loadKeywordSuggestion(query) {
        this.props.fetchSearchKeywordSuggestion(query);
    }
    onClickSuggestion = (keyword) => {
        if (this.props.fromSearch === "true") {
            //this.setState({isSearchInputFocus: false});
            //console.log("INi dari FROMSEARCH");
            this.setState({ fromSearchNewKeywordSubmitted : true});
            //console.log("Setelah pergantian state");
            //this.searchInput.blur();

            history.push({
                pathname: "/search",
                search: "?q="+keyword+"&src=bar&by=products"
            });
        }
        else {
            history.push({
                pathname: "/search",
                search: "?q="+keyword+"&src=bar&by=products"
            });
        }
    }
    componentDidMount() {
        //console.log("SearchBar Did mount");
        if (this.props.defaultQuery) {
            this.setState({query: this.props.defaultQuery})
        }
    }
    componentDidUpdate() {
        if (this.props.fromSearch==="true") {
            if (this.state.isSearchInputFocus === false) {
                //console.log("SearchNotFocus default query", this.props.defaultQuery)
                if (this.state.query !== this.props.defaultQuery) {
                    this.setState({query: this.props.defaultQuery})
                }
            }
            if (this.state.fromSearchNewKeywordSubmitted === true) {
                //console.log("yeah its, bluring")
                this.searchInput.blur();
            }
        }
        
    }
    doRenderSuggestion() {
        const { suggestion } = this.props;
        //console.log(suggestion.suggest);
        if (suggestion.isLoaded === true) {
            if (suggestion.suggest.completion && suggestion.suggest.completion.length >= 1) {
                const renderedList = suggestion.suggest.completion.map(item => {
                    return (
                        <div className="item" onClick={()=> {this.onClickSuggestion(item.autocomplete)}}>
                            <div className="content">
                                <div className="suggestion-items">{item.autocomplete}</div>
                                di {item.source.sub_kategori}
                            </div>
                        </div>
                    );
                });
                return (
                    <div>
                        <div className="ui middle aligned selection list">
                            {renderedList}
                        </div>
                    </div>
                );
            }
            else if (suggestion.suggest.phrase.length >= 0) {
                const renderedList = suggestion.suggest.phrase.map(item => {
                    return (
                        <div className="item" onClick={()=> {this.onClickSuggestion(item.autocomplete)}}>
                            <div className="content">
                                <div className="suggestion-items">{item.autocomplete}</div>
                            </div>
                        </div>
                    );
                })
                return (
                    <div>
                        <div className="ui middle aligned selection list">
                            {renderedList}
                        </div>
                    </div>
                );
            }
            else {
                return <div></div>
            }
        }
    }
    doRenderSearchInputSuggestion() {
        if (this.state.isSearchInputFocus === true && this.state.query !== "") {
            console.log("Render Search input suggestion....");
            return (
                <div className="search-panel" onMouseEnter={this.onMouseEnterPanel} onMouseLeave={this.onMouseLeavePanel}>
                    <div className="ui segment search-suggest-panel">
                        {this.doRenderSuggestion()}
                    </div>
                </div>
            );
        }
        else {
            //console.log("Mouse di render", this.state.isMouseInsidePanel)
            return (
                <div></div>
            )
        }
    }
    doRenderSignInUtilityButtons() {
        if (this.cookies.get("user")) {

            return (
                <div className="right menu">
                    <div className="borderless item">
                        <UtilitiesMenu />
                    </div>
                    <div className="borderless item">
                    <AccountMenu />
                    </div>
                    
                </div>
            );
        }
        else {
            return (
                <div className="right menu">
                    <div className="borderless item">
                        <UtilitiesMenu />
                    </div>
                    <div className="borderless item">
                        <div className="search-holder-utilities-buttons">
                            <Link to="/login" className="ui green button">Masuk</Link>
                        </div>
                        <div className="search-holder-utilities-buttons">
                            <Link to="/register" className="ui red basic button">Daftar</Link>
                        </div>
                    </div>
                </div>
            )
        }
    }
    render() {
        return (
            <div>
                <div className="ui fixed menu search-menu" ref={(divElement) => {this.divElement = divElement}}>
                    <div className="ui container" >
                        <Link to="/" className="header borderless item" style={{border: "none", borderRight: "none"}}>
                            <img alt="henata" style={{width: '100px'}} className="logo" src="/assets/img/logo.png">
                            </img>
                        </Link>
                        
                        <div className="search-holder">
                            <div onMouseEnter={this.onMouseEnterPanel} onMouseLeave={this.onMouseLeavePanel}>
                                <form className="ui form" onSubmit={this.onFormSubmit}>
                                    <div className="ui fluid action input">
                                    <input ref={(element) => {this.searchInput = element}} onFocus={this.onSearchInputFocus} onBlur={this.onSearchInputBlur} className="search-input" type="search" placeholder="Cari produk..." aria-label="Search" value={this.state.query} onChange={this.onSearchInputChange} />
                                    <button className="ui button" type="submit"><i className="search icon"></i></button>
                                    </div>
                                </form>
                            </div>
                            {this.doRenderSearchInputSuggestion()}
                        </div>
                        
                        {this.doRenderSignInUtilityButtons()}
                    </div>
                    
                </div>
                
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return { suggestion : state.searchKeywordSuggestion }
}
const connectSearchPanel = connect(mapStateToProps, {fetchSearchKeywordSuggestion, resetSearchKeywordSuggestion})(SearchBar);
export {connectSearchPanel};