import React from "react";

import "../css/membership.css"
import history from "../../history";

class Membership extends React.Component {
  onRegisterClick = (event) => {
    history.push("/register");
  }
  renderSecLeadText() {
    return (
      <div className='lead-card'>
        <h3 className="title">
          Membership sekarang!
        </h3>
        <div>Nikmati berbagai benefit dengan menjadi member henatastore!</div>
        <div>- Point reward</div>
        <div>- Exclusive promotion</div>
        <div>- Special price</div>
        {this.renderSecLeadButton()}
      </div>
    )
  }
  renderSecImage() {
    return <div className='img'></div>
  }
  renderSecLeadButton() {
    return (
      <button className="btn-membership-register" onClick={this.onRegisterClick}>
        Daftar Sekarang
      </button>
    )
  }
  render() {
    return (
      <div className='secmem-container'>
        <div className='left-element'>
          {this.renderSecLeadText()}
        </div>
        <div className='right-element'>
          {/* gambar untuk  membership*/}
          <div className='img' />
        </div>
      </div>
    )
  }
}

export default Membership;